import React, { Component } from "react";
import Forecast from "./Forecast";
import InputCollection from "../Collections/InputCollection";
import { Tabs, Tab } from "react-bootstrap";
import ScenarioForm from "./ScenarioForm";

class Scenario extends Component {
  // Should fire actions / use redux properly - lazy for now as there is a lot of volatility
  handleCollectionEdit = collectionName => item => {
    const newCollection = { ...this.props.scenario[collectionName] };

    if (newCollection[item.id]) {
      newCollection[item.id] = item;

      this.props.onScenarioEdited({
        ...this.props.scenario,
        [collectionName]: newCollection
      });
    }
  };

  handleCollectionAdd = collectionName => item => {
    const newValues = {
      ...this.props.scenario[collectionName],
      [item.id]: item
    };
    this.props.onScenarioEdited({
      ...this.props.scenario,
      [collectionName]: newValues
    });
  };

  handleCollectionDelete = collectionName => id => {
    const newValues = { ...this.props.scenario[collectionName] };
    delete newValues[id];
    this.props.onScenarioEdited({
      ...this.props.scenario,
      [collectionName]: newValues
    });
  };

  render = () => {
    const { scenario, forecast } = this.props;

    return (
      <div className="scenario-view">
        <Forecast forecast={forecast} />
        <div className="mft-edit">
          <Tabs defaultActiveKey="scenario">
            <Tab eventKey="scenario" title="Scenario">
              {/* TODO: should be able to remove forecast from below, doesn't make sense for form to know about forecast data */}
              <ScenarioForm
                scenario={scenario}
                onEdit={this.props.onScenarioEdited}
                forecast={forecast}
              />
            </Tab>
            <Tab eventKey="accounts" title="Accounts">
              <InputCollection
                metadata={this.props.configuration.accounts}
                // TODO: this should be done in a selector
                collection={Object.values(scenario.accounts)}
                startingYear={scenario.startingYear}
                onAdd={this.handleCollectionAdd("accounts")}
                onEdit={this.handleCollectionEdit("accounts")}
                onDelete={this.handleCollectionDelete("accounts")}
              />
            </Tab>
            <Tab eventKey="incomeSource" title="Income Source">
              <InputCollection
                metadata={this.props.configuration.incomeSources}
                // TODO: this should be done in a selector
                collection={Object.values(scenario.incomeSources)}
                startingYear={scenario.startingYear}
                onAdd={this.handleCollectionAdd("incomeSources")}
                onEdit={this.handleCollectionEdit("incomeSources")}
                onDelete={this.handleCollectionDelete("incomeSources")}
              />
            </Tab>
            <Tab eventKey="expenses" title="Expenses">
              <InputCollection
                metadata={this.props.configuration.expenses}
                // TODO: this should be done in a selector
                collection={Object.values(scenario.expenses)}
                startingYear={scenario.startingYear}
                onAdd={this.handleCollectionAdd("expenses")}
                onEdit={this.handleCollectionEdit("expenses")}
                onDelete={this.handleCollectionDelete("expenses")}
              />
            </Tab>
          </Tabs>
        </div>
      </div>
    );
  };
}

export default Scenario;
