import React, { Component } from "react";
import Scenario from "./Scenario";
import ScenarioCompare from "./ScenarioCompare";
import ScenarioList from "./ScenarioList";
import {
  getScenarioForecasts,
  getConfiguration,
  getScenarios,
  getUser,
  getScenarioViewState
} from "../state/selectors";
import { connect } from "react-redux";
import { compose } from "recompose";
import * as ACTIONS from "../state/actions";

class ScenarioContainer extends Component {
  componentWillUnmount = () => {
    this.saveScenarios();
  };

  saveScenarios = () => {
    // TODO: handle async success/error
    if (
      !this.props.demo &&
      !this.props.viewState.loading &&
      this.props.viewState.unsavedChange
    ) {
      this.props.onSaveScenarios(this.props.scenarios);
    }
  };

  render = () => {
    const { viewState } = this.props;
    return viewState.loading ? (
      <div>Loading...</div>
    ) : (
      <div className="scenario-container">
        <div className="mft-sidebar">
          {!this.props.demo ? (
            <div>
              <button
                className="btn btn-dark btn-block"
                disabled={!viewState.unsavedChange}
                onClick={this.saveScenarios}
              >
                Save
              </button>
            </div>
          ) : null}
          <div>
            <button
              className="btn btn-dark btn-block"
              onClick={
                viewState.compare
                  ? this.props.onCompareExit
                  : this.props.onCompareEnter
              }
            >
              {viewState.compare ? "Exit" : "Enter"} Compare
            </button>
          </div>
          <div className="text-center">
            {viewState.compare ? "Select scenarios" : "Select a scenario"}
          </div>
          <ScenarioList
            scenarios={this.props.scenarios}
            onScenarioCopy={this.props.onScenarioCopy}
            onScenarioDelete={this.props.onScenarioDelete}
            selectedScenarioIds={
              viewState.compare
                ? viewState.compareSelectedScenarioIds
                : [viewState.selectedScenarioId]
            }
            onClick={
              viewState.compare
                ? this.props.onCompareScenarioSelect
                : this.props.onScenarioSelect
            }
          />
        </div>
        <div className="mft-content">
          {// TODO: forecast / scenario should be merged clean this up
          viewState.compare ? (
            <ScenarioCompare
              configuration={this.props.configuration}
              forecasts={this.props.forecasts}
              scenarios={viewState.compareSelectedScenarioIds.map(
                scenarioId => this.props.scenarios[scenarioId]
              )}
              onScenarioEdited={this.props.onScenarioEdited}
            />
          ) : (
            <Scenario
              configuration={this.props.configuration}
              scenario={this.props.scenarios[viewState.selectedScenarioId]}
              forecast={this.props.forecasts[viewState.selectedScenarioId]}
              onScenarioEdited={this.props.onScenarioEdited}
              key={viewState.selectedScenarioId}
            />
          )}
        </div>
      </div>
    );
  };
}

function mapStateToProps(state) {
  return {
    configuration: getConfiguration(state),
    scenarios: getScenarios(state),
    forecasts: getScenarioForecasts(state),
    user: getUser(state),
    viewState: getScenarioViewState(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onScenarioEdited: scenario => {
      dispatch({ type: ACTIONS.SCENARIO_UPDATE, scenario });
    },
    onScenarioCopy: scenarioId => {
      dispatch({ type: ACTIONS.SCENARIO_COPY, scenarioId });
    },
    onScenarioDelete: scenarioId => {
      dispatch({ type: ACTIONS.SCENARIO_DELETE, scenarioId });
    },
    onScenarioSelect: scenarioId => {
      dispatch({ type: ACTIONS.SCENARIO_SELECT, scenarioId });
    },
    onCompareEnter: () => {
      dispatch({ type: ACTIONS.SCENARIO_COMPARE_ENTER });
    },
    onCompareExit: () => {
      dispatch({ type: ACTIONS.SCENARIO_COMPARE_EXIT });
    },
    onCompareScenarioSelect: scenarioId => {
      dispatch({ type: ACTIONS.SCENARIO_COMPARE_SELECT, scenarioId });
    }
  };
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ScenarioContainer);
