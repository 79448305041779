import { combineReducers } from "redux";
import scenarioReducer from "./scenarioReducer";
import sessionReducer from "./sessionReducer";

const rootReducer = combineReducers({
  scenarioState: scenarioReducer,
  sessionState: sessionReducer
});

export default rootReducer;
