import "bootstrap/dist/css/bootstrap.min.css";
import "react-vis/dist/style.css";
import React from "react";
import { connect } from "react-redux";
import "./App.scss";
import { getUser } from "./state/selectors";
import MyNavbar from "./Navbar";
import Home from "./components/home";
import Login from "./components/login";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { withAuthentication } from "./components/session";
import * as ROUTES from "./constants/routes";
import { compose } from "redux";
import UserScenarios from "./Scenario/UserScenarios";
import DemoScenarios from "./Scenario/DemoScenarios";

const mapStateToProps = state => {
  return {
    user: getUser(state)
  };
};

const App = ({ user }) => {
  return (
    <Router>
      <div>
        <div className="mft-navbar sticky-top">
          <MyNavbar user={user} />
        </div>

        <div className="mft-view">
          <Route exact path="/" component={Home} />
          <Route path={ROUTES.SIGNIN} component={Login} />
          <Route path={ROUTES.PLAN} component={UserScenarios} />
          <Route path={ROUTES.DEMO} component={DemoScenarios} />
        </div>
      </div>
    </Router>
  );
};

export default compose(
  withAuthentication,
  connect(mapStateToProps)
)(App);
