import React, { useState } from "react";
import { Form, Dropdown } from "react-bootstrap";

const Multiselect = props => {
  const [selectAll, setSelectAll] = useState(
    props.options.every(s => s.selected)
  );
  const [selections, setSelections] = useState(
    props.options.reduce((accum, curr) => {
      accum[curr.id] = curr.selected;
      return accum;
    }, {})
  );
  const [showDropdown, setShowDropdown] = useState(false);

  const syncSelectAll = state => {
    var options = Object.keys(state).filter(k => k !== "all");
    setSelectAll(options.every(o => state[o]));
  };

  const handleSelect = newState => {
    setSelections(newState);
    props.onSelect(newState);
  };

  const handleToggle = (open, event, metadata) => {
    // If open is false and type of event is 'close'
    if (!open && !["click", "select"].includes(metadata.source)) {
      setShowDropdown(false);
    } else {
      setShowDropdown(true);
    }
  };

  return (
    <Dropdown show={showDropdown} onToggle={handleToggle}>
      <Dropdown.Toggle variant="dark" size="sm">
        {props.name}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item
          onSelect={() => {
            const newSelectAll = !selectAll;
            setSelectAll(newSelectAll);
            const newState = Object.keys(selections).reduce((accum, curr) => {
              accum[curr] = newSelectAll;
              return accum;
            }, {});

            handleSelect(newState);
          }}
        >
          <Form.Check
            checked={selectAll}
            onChange={e => e.preventDefault()}
            type="checkbox"
            label="Select All"
          />
        </Dropdown.Item>
        <Dropdown.Divider />
        {props.options.map(selection => (
          <Dropdown.Item
            key={selection.id}
            onSelect={() => {
              const newState = {
                ...selections,
                [selection.id]: !selections[selection.id]
              };
              syncSelectAll(newState);
              handleSelect(newState);
            }}
          >
            <Form.Check
              checked={selections[selection.id]}
              onChange={e => e.preventDefault()}
              type="checkbox"
              label={selection.name}
            />
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default Multiselect;
