import React from "react";

const Home = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 m-2">
          <div className="row justify-content-center">
            <h2>Welcome to MyFinancialTool.com!</h2>
          </div>
          <div className="row justify-content-center">
            <span>
              This site is current in beta and available for user feedback.
            </span>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 m-2">
          <div className="row justify-content-center">
            <h4>
              <a href="https://www.surveymonkey.com/r/V8KZYW2">
                Please take the survey after experimenting with the tool
              </a>
            </h4>
          </div>
        </div>
      </div>
      <div className="row justify-content-center m-2">
        <div className="col-6">
          <div className="row">
            <h4>Purpose</h4>
          </div>
          <div className="row">
            <span>Visualize Your Financial Independence</span>
          </div>
          <div className="row">
            <ul>
              <li>Model account values and wealth accumulation</li>
              <li>Forecast your cash flow and account balances</li>
              <li>Plan contributions and distributions</li>
              <li>Rapidly compare difference scenarios</li>
              <li>Easier than a spreadsheet</li>
            </ul>
          </div>
        </div>
        <div className="col-6">
          <div className="row">
            <h4>Use-Cases</h4>
          </div>
          <div className="row">
            <ul>
              <li>Model your path to financial independence</li>
              <li>Plan a withdrawal strategy</li>
              <li>Compare different market returns or withdrawal rates</li>
              <li>Compare the long-term impact of financial decisions</li>
              <ul>
                <li>300k home versus 400k home</li>
                <li>15 year vs 30 year mortgage</li>
                <li>Paying down debt versus investing</li>
                <li>Saving extra money</li>
                <li>Different debt paydown strategies</li>
              </ul>
            </ul>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-6">
          <div className="row">
            <h4>Overview</h4>
          </div>
          <div className="row">
            <iframe
              title="overview"
              width="560"
              height="315"
              src="https://www.youtube.com/embed/r9pS48W7ccI"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </div>
        </div>
        <div className="col-6">
          <div className="row">
            <h4>Create Your Plan</h4>
          </div>
          <div className="row">
            <iframe
              title="create"
              width="560"
              height="315"
              src="https://www.youtube.com/embed/57hWKLAJKdw"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
