export const getNextId = (dictionary, idProperty = "id") => {
  const newId =
    Object.values(dictionary).reduce((accum, curr) => {
      if (curr[idProperty] > accum) {
        return curr[idProperty];
      }
      return accum;
    }, 0) + 1;

  return newId;
};
