import React, { Component } from "react";
import ReactTable, { ReactTableDefaults } from "react-table";
import "./DataTable.css";
import "react-table/react-table.css";
import NumberFormat from "react-number-format";

const numberFormatAccessor = accessor => {
  return d => {
    let value = "";
    if (typeof accessor === "string") {
      value = d[accessor];
    } else {
      value = accessor(d);
    }

    return (
      <NumberFormat
        value={value}
        displayType={"text"}
        decimalScale={0}
        thousandSeparator={true}
      />
    );
  };
};

class DataTable extends Component {
  render = () => {
    const columns = this.props.columns.map(column => {
      if (column.numberFormat) {
        column.accessor = numberFormatAccessor(column.accessor);
        delete column.numberFormat;
      }

      return column;
    });

    return (
      <ReactTable
        data={this.props.data}
        columns={columns}
        showPagination={false}
        minRows={0}
        defaultPageSize={500}
        column={{
          ...ReactTableDefaults.column,
          minWidth: 75,
          headerClassName: "mft-table-header"
        }}
        style={{ height: "100%", width: "100%" }}
        className="-striped -highlight"
      />
    );
  };
}

export default DataTable;
