import React, { Component } from "react";
import DataTable from "../Shared/DataTable";

class ForecastCashFlow extends Component {
  render = () => {
    var years = this.props.data;
    var columns = [
      {
        id: "year",
        Header: "Year",
        accessor: "year"
      },
      {
        id: "startingValue",
        Header: "Start Net Worth",
        accessor: "startingValue",
        numberFormat: true
      },
      {
        id: "income",
        Header: "Income",
        accessor: "income",
        numberFormat: true
      },
      {
        id: "expenses",
        Header: "Expenses",
        accessor: "expenses",
        numberFormat: true
      },
      {
        id: "contributions",
        Header: "Contributions",
        accessor: "contributions",
        numberFormat: true
      },
      {
        id: "unallocated",
        Header: "Unallocated",
        accessor: "unallocated",
        numberFormat: true
      },
      {
        id: "growth",
        Header: "Growth",
        accessor: "growth",
        numberFormat: true
      },
      {
        id: "dividend",
        Header: "Dividend",
        accessor: "dividend",
        numberFormat: true
      },
      {
        id: "endingValue",
        Header: "End Net Worth",
        accessor: "endingValue",
        numberFormat: true
      },
      {
        id: "targetPercent",
        Header: "Target Percent",
        accessor: "targetPercent",
        numberFormat: true
      }
    ];

    return (
      <DataTable
        data={Object.keys(years).map(year => {
          var yearData = years[year];
          return {
            year,
            startingValue: yearData.startingValue || 0,
            income: yearData.income || 0,
            expenses: yearData.expense || 0,
            contributions: yearData.contribution || 0,
            unallocated: yearData.unallocated || 0,
            growth: yearData.growth || 0,
            dividend: yearData.dividend || 0,
            endingValue: yearData.endingValue || 0,
            targetPercent: yearData.targetPercent || 0
          };
        })}
        columns={columns}
      />
    );
  };
}

export default ForecastCashFlow;
