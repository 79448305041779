import { createSelector } from "reselect";
import { amortizationSchedule } from "../../Shared/Calculator";

const getScenarioState = state => state.scenarioState;
export const getScenarios = createSelector(
  getScenarioState,
  scenarioState => scenarioState.scenarios
);
export const getConfiguration = createSelector(
  getScenarioState,
  scenarioState => scenarioState.configuration
);
export const getScenarioViewState = createSelector(
  getScenarioState,
  scenarioState => scenarioState.viewState
);

const selectApplicableVariableRules = (variableRules, year) => {
  return Object.values(variableRules).filter(
    rule => rule.fromYear <= year && (!rule.toYear || rule.toYear >= year)
  );
};

const forecastDebtAccount = (account, variables) => {
  let forecast = {};
  let forecastYear;
  let startingBalance = 0;

  var accountVariables = selectApplicableVariableRules(
    account.variableRules,
    variables.startingYear
  );

  // TODO: fix this - should we support multiple rules?
  // TODO: we need to ensure we have one of these for a debt account, or how to early exit & warn user
  const debtVariableRule = accountVariables[0] || accountVariables[0];
  const interestRate = (debtVariableRule && debtVariableRule.interestRate) || 0;
  const monthlyPayment =
    (debtVariableRule && debtVariableRule.monthlyPayment) || 0;

  var schedule = amortizationSchedule(
    account.balance,
    interestRate / 100 / 12,
    monthlyPayment,
    null,
    12
  );

  startingBalance = -1 * account.balance;
  for (let i = 0; i <= variables.years; i++) {
    forecastYear = variables.startingYear + i;

    const scheduleYear = schedule[i];
    const endingBalance = scheduleYear ? -1 * scheduleYear.endingPrincipal : 0;
    forecast[forecastYear] = {
      startingBalance,
      endingBalance,
      principalPayment: scheduleYear ? scheduleYear.principalPayment : 0,
      dividend: 0
    };
    startingBalance = endingBalance;
  }

  return {
    years: forecast,
    startValue: -1 * account.balance,
    endValue: -1 * schedule[schedule.length - 1].endingPrincipal
  };
};

const forecastGrowthAccount = (account, variables) => {
  let forecast = {};
  let forecastYear;
  let startingBalance = 0;

  startingBalance = account.balance;
  for (let i = 0; i <= variables.years; i++) {
    forecastYear = variables.startingYear + i;

    const variableRules = selectApplicableVariableRules(
      account.variableRules,
      forecastYear
    );

    var growth = 0,
      dividendGrowth = 0,
      contribution = 0,
      dividend = 0;

    for (let r = 0; r < variableRules.length; r++) {
      const variableRule = variableRules[r];
      const setGrowth =
        (startingBalance *
          (variableRule.growthRate || variables.growthRate || 0)) /
        100;
      const setDividend =
        (startingBalance *
          (variableRule.dividendRate || variables.dividendRate || 0)) /
        100;

      growth += setGrowth;

      dividendGrowth += variableRule.reinvestDividend ? setDividend : 0;

      contribution += variableRule.yearlyChange || 0;

      dividend += setDividend;
    }

    const endingBalance =
      startingBalance + growth + dividendGrowth + contribution;

    forecast[forecastYear] = {
      startingBalance,
      endingBalance,
      contribution,
      growth,
      dividend
    };
    startingBalance = endingBalance;
  }

  return {
    years: forecast,
    startValue: account.balance,
    endValue: startingBalance
  };
};

// TODO: extract this
const forecastAccount = (account, variables) => {
  if (account.accountType === "debt") {
    return forecastDebtAccount(account, variables);
  }

  return forecastGrowthAccount(account, variables);
};

const forecastIncomeSource = (incomeSource, variables) => {
  let startingIncome = 0;
  if (incomeSource.fromYear <= variables.startingYear) {
    startingIncome = incomeSource.afterTaxIncome;
  }

  const forecast = {
    [variables.startingYear]: {
      income: startingIncome
    }
  };

  let forecastYear;
  let previousIncome = startingIncome;
  for (let i = 1; i <= variables.years; i++) {
    forecastYear = variables.startingYear + i;
    let yearlyIncome = 0;

    if (
      incomeSource.fromYear <= forecastYear &&
      (!incomeSource.toYear || incomeSource.toYear >= forecastYear)
    ) {
      yearlyIncome = previousIncome || incomeSource.afterTaxIncome;
      const variableRules = selectApplicableVariableRules(
        incomeSource.variableRules,
        forecastYear
      );

      for (let r = 0; r < variableRules.length; r++) {
        const variableRule = variableRules[r];
        const incomeGrowth =
          (previousIncome * (variableRule.growthRate || 0)) / 100;
        yearlyIncome += incomeGrowth;
      }
    }

    forecast[forecastYear] = {
      income: yearlyIncome
    };

    previousIncome = yearlyIncome;
  }

  return {
    years: forecast
  };
};

const forecastExpense = (expense, variables) => {
  let startingExpense = 0;
  if (expense.fromYear <= variables.startingYear) {
    startingExpense = expense.expenseAmount;
  }

  const forecast = {
    [variables.startingYear]: {
      expense: startingExpense
    }
  };

  let forecastYear;
  let previousExpense = startingExpense;
  for (let i = 1; i <= variables.years; i++) {
    forecastYear = variables.startingYear + i;
    let yearlyExpense = 0;

    if (
      expense.fromYear <= forecastYear &&
      (!expense.toYear || expense.toYear >= forecastYear)
    ) {
      yearlyExpense = previousExpense || expense.expenseAmount;
      const variableRules = selectApplicableVariableRules(
        expense.variableRules,
        forecastYear
      );

      for (let r = 0; r < variableRules.length; r++) {
        const variableRule = variableRules[r];
        const expenseGrowth =
          (previousExpense * (variableRule.growthRate || 0)) / 100;
        yearlyExpense += expenseGrowth;
      }
    }

    forecast[forecastYear] = {
      expense: yearlyExpense
    };

    previousExpense = yearlyExpense;
  }

  return {
    years: forecast
  };
};

const createNetWorthSeriesData = yearForecast => {
  const totals = [
    {
      name: "Net Worth",
      data: Object.keys(yearForecast).map(year => {
        return {
          x: year,
          y: yearForecast[year].endingValue
        };
      })
    }
  ];

  return totals;
};

const createAccountSeriesData = (accounts, accountTypes) => {
  var signedAccounts = accounts.reduce(
    (accum, account) => {
      var normalBalance = accountTypes[account.accountType].normalBalance;
      accum[normalBalance].push(account);

      return accum;
    },
    { credit: [], debit: [] }
  );

  // used to keep track of stacking for each year after account balances are placed.
  var stackPosition = {};

  // take a two step approach to stacking - in the negative direction, then positive.  Stack from 0 out
  signedAccounts.credit.sort((a, b) => a.endValue - b.endValue);
  var creditGraphData = signedAccounts.credit.map(account => {
    var chartData = Object.keys(account.years).map(year => {
      var yearBalance = account.years[year].endingBalance;
      var stackFrom = stackPosition[year] || 0;
      stackPosition[year] = stackFrom + yearBalance;

      return {
        x: year,
        y: stackFrom,
        y0: stackFrom + yearBalance
      };
    });

    return {
      name: account.name,
      data: chartData
    };
  });

  // TODO: consolidate this code and the code above 95%+ same
  // reset stack position and stack positive
  stackPosition = {};
  signedAccounts.debit.sort((a, b) => b.endValue - a.endValue);
  var debitGraphData = signedAccounts.debit.map(account => {
    var chartData = Object.keys(account.years).map(year => {
      var yearBalance = account.years[year].endingBalance;
      var stackFrom = stackPosition[year] || 0;
      stackPosition[year] = stackFrom + yearBalance;

      return {
        x: year,
        y: stackFrom + yearBalance,
        y0: stackFrom
      };
    });

    return {
      name: account.name,
      data: chartData
    };
  });

  return creditGraphData.concat(debitGraphData);
};

export const getScenarioForecasts = createSelector(
  getScenarios,
  getConfiguration,
  (scenarios, configuration) => {
    var forecasts = Object.keys(scenarios).reduce((forecasts, scenarioId) => {
      const scenario = scenarios[scenarioId];
      const targetNetWorth =
        scenario.targetYearlyIncome / (scenario.targetWithdrawalRate / 100);
      // TODO: handle starting year differently, currently assuming starting year = this year
      const variables = {
        startingYear: scenario.startingYear,
        years: scenario.years,
        growthRate: 0, // scenario.growthRate,
        dividendRate: 0 //scenario.dividendRate
      };
      const accountForecasts = Object.values(scenario.accounts).map(account => {
        const accountYears = forecastAccount(account, variables);

        return {
          id: account.id,
          name: account.name,
          accountType: account.accountType,
          ...accountYears
        };
      });

      const incomeForecasts = Object.values(scenario.incomeSources).map(
        incomeSource => {
          const incomeSourceYears = forecastIncomeSource(
            incomeSource,
            variables
          );

          return {
            name: incomeSource.name,
            incomeType: incomeSource.incomeType,
            ...incomeSourceYears
          };
        }
      );

      const expenseForecasts = Object.values(scenario.expenses).map(expense => {
        const expenseYears = forecastExpense(expense, variables);

        return {
          name: expense.name,
          incomeType: expense.expenseType,
          ...expenseYears
        };
      });

      const yearForecast = accountForecasts.reduce((result, account) => {
        Object.keys(account.years).forEach(year => {
          result[year] = {
            ...result[year],
            // TODO: better way to do this?
            startingValue:
              ((result[year] && result[year].startingValue) || 0) +
              account.years[year].startingBalance,
            endingValue:
              ((result[year] && result[year].endingValue) || 0) +
              account.years[year].endingBalance,
            contribution:
              ((result[year] && result[year].contribution) || 0) +
              (account.years[year].contribution || 0),
            growth:
              ((result[year] && result[year].growth) || 0) +
              (account.years[year].growth || 0),
            dividend:
              ((result[year] && result[year].dividend) || 0) +
              (account.years[year].dividend || 0),
            accounts: {
              ...(result[year] && result[year].accounts),
              [account.name]: account.years[year]
            }
          };

          result[year].targetPercent =
            (result[year].endingValue / targetNetWorth) * 100;
        });

        return result;
      }, {});

      incomeForecasts.reduce((result, incomeSource) => {
        Object.keys(incomeSource.years).forEach(year => {
          result[year] = {
            ...result[year],
            income:
              ((result[year] && result[year].income) || 0) +
              incomeSource.years[year].income,
            incomeSources: {
              ...(result[year] && result[year].incomeSources),
              [incomeSource.name]: incomeSource.years[year]
            }
          };
        });

        return result;
      }, yearForecast);

      expenseForecasts.reduce((result, expense) => {
        Object.keys(expense.years).forEach(year => {
          result[year] = {
            ...result[year],
            expense:
              ((result[year] && result[year].expense) || 0) +
              expense.years[year].expense,
            expenses: {
              ...(result[year] && result[year].expenses),
              [expense.name]: expense.years[year]
            }
          };
        });

        return result;
      }, yearForecast);

      Object.keys(yearForecast).forEach(year => {
        yearForecast[year].unallocated =
          (yearForecast[year].income || 0) -
          (yearForecast[year].expense || 0) -
          (yearForecast[year].contribution || 0);
      });

      return {
        ...forecasts,
        [scenarioId]: {
          name: scenario.name,
          targetNetWorth,
          variables,
          years: yearForecast,
          incomeSources: incomeForecasts,
          expenses: expenseForecasts,
          accounts: accountForecasts,
          accountSeries: createAccountSeriesData(
            accountForecasts,
            configuration.accounts.types
          ),
          totalSeries: createNetWorthSeriesData(yearForecast)
        }
      };
    }, {});

    return forecasts;
  }
);
