import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withFirebase } from "../firebase";

const withAuthentication = Component => {
  class WithAuthentication extends React.Component {
    componentDidMount = () => {
      this.listener = this.props.firebase.auth.onAuthStateChanged(
        user => {
          this.props.onSetUser(user);
        },
        () => {
          this.props.onSetUser(null);
        }
      );
    };

    componentWillUnmount = () => {
      this.listener();
    };

    render() {
      return <Component {...this.props} />;
    }
  }

  const mapDispatchToProps = dispatch => ({
    onSetUser: user => dispatch({ type: "SESSION_USER_SET", user })
  });

  return compose(
    withFirebase,
    connect(
      null,
      mapDispatchToProps
    )
  )(WithAuthentication);
};

export default withAuthentication;
