import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import * as ACTIONS from "../state/actions";
import demoData from "../data/data-demo.json";

import ScenarioContainer from "./ScenarioContainer";

class DemoScenarios extends Component {
  componentDidMount = () => {
    this.props.onScenariosLoaded(demoData.scenarios);
  };

  render = () => {
    return <ScenarioContainer demo={true} />;
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onScenariosLoaded: scenarios => {
      dispatch({ type: ACTIONS.SCENARIOS_LOADED, scenarios });
    }
  };
}

export default compose(
  connect(
    null,
    mapDispatchToProps
  )
)(DemoScenarios);
