const defaultState = {
  user: null
};

const sessionReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "SESSION_USER_SET": {
      return Object.assign({}, state, {
        ...state,
        user: action.user
      });
    }
    default:
      return state;
  }
};

export default sessionReducer;
