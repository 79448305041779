import React from "react";
import ReactDOM from "react-dom";
import * as ReactRedux from "react-redux";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faEdit,
  faSave,
  faTrashAlt,
  faCopy,
  faPlusCircle,
  faMinusCircle,
  faChevronCircleDown,
  faChevronCircleUp
} from "@fortawesome/free-solid-svg-icons";

import store from "./state";
import Firebase, { FirebaseContext } from "./components/firebase";

library.add(faCopy);
library.add(faEdit);
library.add(faSave);
library.add(faTrashAlt);
library.add(faPlusCircle);
library.add(faMinusCircle);
library.add(faChevronCircleDown);
library.add(faChevronCircleUp);

ReactDOM.render(
  <ReactRedux.Provider store={store}>
    <FirebaseContext.Provider value={new Firebase()}>
      <App />
    </FirebaseContext.Provider>
  </ReactRedux.Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
