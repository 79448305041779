import React, { useState } from "react";
import DataTable from "../Shared/DataTable";
import Multiselect from "../Shared/Multiselect";

const ForecastAccounts = props => {
  const accounts = props.forecast.accounts;

  const [accountSelections, setAccountSelections] = useState(
    accounts.reduce((accum, curr) => {
      accum[curr.id] = true;
      return accum;
    }, {})
  );

  const accountOptions = accounts.map(account => {
    return {
      id: account.id,
      name: account.name,
      selected: true
    };
  });

  const valueOptions = [
    { id: "balance", name: "Balance", selected: true },
    { id: "growth", name: "Growth", selected: false },
    { id: "dividend", name: "Dividend", selected: false }
  ];
  const [valueSelections, setValueSelections] = useState({
    balance: true,
    growth: false,
    dividend: false
  });

  const selectedAccounts = accounts.filter(
    account => accountSelections[account.id]
  );

  const forecastColumns = [
    {
      Header: "Year",
      accessor: "year"
    },
    ...selectedAccounts.flatMap(account => {
      return [
        ...(valueSelections["balance"]
          ? [
              {
                id: account.name,
                Header: account.name,
                accessor: d => d.accounts[account.name].endingBalance,
                numberFormat: true
              }
            ]
          : []),
        // TODO: clean this up needs to be displayed better
        ...(valueSelections["growth"]
          ? [
              {
                id: account.name + "growth",
                Header: account.name + " Growth",
                accessor: d => d.accounts[account.name].growth,
                numberFormat: true
              }
            ]
          : []),
        ...(valueSelections["dividend"]
          ? [
              {
                id: account.name + "dividend",
                Header: account.name + " Dividend",
                accessor: d => d.accounts[account.name].dividend,
                numberFormat: true
              }
            ]
          : [])
      ];
    }),

    {
      id: "netWorth",
      Header: "Net Worth",
      accessor: "endingValue",
      numberFormat: true
    },
    {
      id: "targetPercent",
      Header: "Target Percent",
      accessor: "targetPercent",
      numberFormat: true
    }
  ];

  return (
    // TODO: remove inline styling
    <div className="mft-forecast-accounts">
      <div className="mft-forecast-accounts-filter">
        <Multiselect
          name="Accounts"
          options={accountOptions}
          onSelect={setAccountSelections}
        />
        <Multiselect
          name="Values"
          options={valueOptions}
          onSelect={setValueSelections}
        />
      </div>
      <div className="mft-forecast-accounts-table">
        <DataTable
          data={Object.keys(props.forecast.years).map(year => {
            return {
              year,
              ...props.forecast.years[year]
            };
          })}
          columns={forecastColumns}
        />
      </div>
    </div>
  );
};

export default ForecastAccounts;
