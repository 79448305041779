import React, { Component } from "react";
import { extractInputData } from "../Shared/FormHelpers";
import ItemVariableInput from "./ItemVariableInput";

class AddCollectionItem extends Component {
  constructor(props) {
    super(props);

    const defaultItem = props.metadata.variables.reduce(
      (accum, variable) => {
        accum[variable.id] = "";

        return accum;
      },
      // We probably don't want to add these if there aren't any in the metadata - handle later
      { variableRules: { "0": { id: 0, fromYear: props.startingYear } } }
    );

    this.state = {
      item: defaultItem
    };
  }

  onFieldChange = type => event => {
    const inputData = extractInputData(type, event);

    this.setState({
      item: {
        ...this.state.item,
        [inputData.name]: inputData.value
      }
    });
  };

  render = () => {
    const itemName = this.props.metadata.itemDisplayName;

    return (
      <div>
        <h4>Add {itemName}</h4>
        <form>
          {this.props.metadata.variables.map(variable => {
            return (
              <div
                key={variable.id}
                className={
                  "form-group " + (variable.required ? "required" : "")
                }
              >
                <label className="control-label" htmlFor={variable.id}>
                  {variable.displayName}
                </label>
                <ItemVariableInput
                  variable={variable}
                  metadata={this.props.metadata}
                  value={this.state.item[variable.id]}
                  onChange={this.onFieldChange(variable.type)}
                />
              </div>
            );
          })}
          <button
            className="btn"
            onClick={() => this.props.onSave(this.state.item)}
          >
            Save
          </button>
          <button className="btn" onClick={this.props.onCancel}>
            Cancel
          </button>
        </form>
      </div>
    );
  };
}

export default AddCollectionItem;
