import React, { Component } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class ScenarioList extends Component {
  handleCopyClick = scenarioId => event => {
    this.props.onScenarioCopy(scenarioId);
    event.stopPropagation();
  };

  handleDeleteClick = scenarioId => event => {
    this.props.onScenarioDelete(scenarioId);
    event.stopPropagation();
  };

  render = () => {
    return (
      <div>
        <ul className="list-group flex-column">
          {Object.values(this.props.scenarios).map(scenario => {
            const active = this.props.selectedScenarioIds.includes(scenario.id);
            return (
              <li
                className={classNames("list-group-item", {
                  "list-group-item-secondary": active
                })}
                key={scenario.id}
                onClick={() => this.props.onClick(scenario.id)}
              >
                <div
                  className="button-container float-right"
                  onClick={this.handleCopyClick(scenario.id)}
                >
                  <FontAwesomeIcon icon="copy" title="Copy Scenario" />
                </div>
                {!this.props.selectedScenarioIds.includes(scenario.id) ? (
                  <div
                    className="button-container float-right"
                    onClick={this.handleDeleteClick(scenario.id)}
                  >
                    <FontAwesomeIcon icon="trash-alt" title="Delete Scenario" />
                  </div>
                ) : null}

                {scenario.name}
              </li>
            );
          })}
        </ul>
      </div>
    );
  };
}

export default ScenarioList;
