import React, { Component } from "react";
import {
  FlexibleXYPlot,
  XAxis,
  YAxis,
  Crosshair,
  HorizontalGridLines,
  DiscreteColorLegend,
  LineSeries
} from "react-vis";
import Scenario from "./Scenario";
import NumberFormat from "react-number-format";

class ScenarioCompare extends Component {
  constructor(props) {
    super(props);
    this.state = {
      crosshairValues: []
    };
  }

  handleNearestX = event => {
    var crosshairValues = Object.values(this.props.forecasts).map(f => {
      return {
        x: event.x,
        title: f.name,
        value: (
          <NumberFormat
            value={f.years[event.x].endingValue}
            displayType={"text"}
            decimalScale={0}
            thousandSeparator={true}
          />
        )
      };
    });

    this.setState({
      crosshairValues
    });
  };

  render = () => {
    return (
      <div className="mft-compare">
        <div className="mft-compare-chart">
          <div className="mft-forecast-graph-legend">
            <DiscreteColorLegend
              height={40}
              orientation="horizontal"
              items={Object.values(this.props.scenarios).map(s => s.name)}
            />
          </div>
          <div className="mft-forecast-graph">
            <FlexibleXYPlot>
              <HorizontalGridLines />
              {this.props.scenarios.map((scenario, index) => {
                const forecast = this.props.forecasts[scenario.id];

                var chartData = Object.keys(forecast.years).map(year => {
                  return {
                    x: year,
                    y: forecast.years[year].endingValue
                  };
                });

                return (
                  <LineSeries
                    data={chartData}
                    key={scenario.id}
                    onNearestX={index === 0 ? this.handleNearestX : null}
                  />
                );
              })}
              <Crosshair
                values={this.state.crosshairValues}
                titleFormat={d => ({ title: "Year", value: d[0].x })}
                itemsFormat={d => d}
              />
              <XAxis
                title={"Year"}
                tickFormat={x => x.toString()}
                // TODO: clean this up
                tickTotal={
                  Object.values(this.props.forecasts)[0].variables.years
                }
              />
              <YAxis title={"Value (1000s)"} tickFormat={y => y / 1000} />
            </FlexibleXYPlot>
          </div>
        </div>
        <div className="mft-compare-scenarios">
          {this.props.scenarios.map(scenario => {
            return (
              <div key={scenario.id} className="mft-compare-container">
                <span className="mft-compare-header">{scenario.name}</span>
                <Scenario
                  configuration={this.props.configuration}
                  scenario={scenario}
                  forecast={this.props.forecasts[scenario.id]}
                  onScenarioEdited={this.props.onScenarioEdited}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  };
}

export default ScenarioCompare;
