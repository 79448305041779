import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import * as ROUTES from "../../constants/routes";

const appConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID
};

const uiConfig = {
  signInSuccessUrl: ROUTES.PLAN,
  signInOptions: [
    {
      provider: app.auth.EmailAuthProvider.PROVIDER_ID,
      requireDisplayName: false
    }
  ]
  // TODO: configure ToS & privacy policy
  // tosUrl and privacyPolicyUrl accept either url string or a callback
  // function.
  // Terms of service url/callback.
  // tosUrl: '<your-tos-url>',
  // Privacy policy url/callback.
  // privacyPolicyUrl: function() {
  //   window.location.assign('<your-privacy-policy-url>');
  // }
};

class Firebase {
  constructor() {
    app.initializeApp(appConfig);

    this.auth = app.auth();
    this.db = app.firestore();
    this.authUiConfig = uiConfig;
  }

  signOut = () => this.auth.signOut();
}

export default Firebase;
