const round = (value, decimals) => {
  return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
};

const annuityPayment = (principal, periodInterestRate, numberOfPayments) => {
  return round(
    principal *
      (periodInterestRate +
        periodInterestRate /
          (Math.pow(1 + periodInterestRate, numberOfPayments) - 1)),
    2
  );
};

export const amortizationSchedule = (
  principal,
  periodInterestRate,
  periodPayment,
  numberOfPayments,
  amortizationPeriod = 1
) => {
  let schedule = [],
    interestPayment = 0,
    principalPayment = 0,
    endingPrincipal = principal;

  // Max at 120 years
  numberOfPayments = numberOfPayments || 1440;

  for (let i = 1; i <= numberOfPayments; i++) {
    let monthlyInterestPayment = round(endingPrincipal * periodInterestRate, 2);
    let monthlyPrincipalPayment = round(
      periodPayment - monthlyInterestPayment,
      2
    );
    interestPayment = round(interestPayment + monthlyInterestPayment, 2);
    principalPayment = round(principalPayment + monthlyPrincipalPayment, 2);
    endingPrincipal = round(endingPrincipal - monthlyPrincipalPayment, 2);

    if (endingPrincipal <= 0) {
      principalPayment = round(principalPayment + endingPrincipal, 2);
      endingPrincipal = 0;

      schedule.push({
        interestPayment,
        principalPayment,
        endingPrincipal
      });
      return schedule;
    }

    if (Math.round(i % amortizationPeriod) === 0) {
      schedule.push({
        interestPayment,
        principalPayment,
        endingPrincipal
      });

      interestPayment = 0;
      principalPayment = 0;
    }
  }

  return schedule;
};

const convertInterestRate = (interestRate, adjustmentMultiplier) => {
  return round(interestRate * adjustmentMultiplier, 6);

  // APY to Monthly
  // return round(
  //   (Math.pow(1 + interestRate / 100, adjustmentMultiplier) - 1) * 100,
  //   6
  // );
};

export const monthlyMortgage = (
  principal,
  yearlyInterestRate,
  numberOfPayments,
  extraPayment = 0
) => {
  const monthlyInterestRate = convertInterestRate(yearlyInterestRate, 1 / 12);
  const monthlyPayment = annuityPayment(
    principal,
    monthlyInterestRate,
    numberOfPayments
  );
  return {
    payment: monthlyPayment,
    monthlyAmortizationSchedule: amortizationSchedule(
      principal,
      monthlyInterestRate,
      monthlyPayment + extraPayment,
      numberOfPayments
    ),
    yearlyAmortizationSchedule: amortizationSchedule(
      principal,
      monthlyInterestRate,
      monthlyPayment + extraPayment,
      numberOfPayments,
      12
    )
  };
};
