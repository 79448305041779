import data from "../../data/data.json";
import { getNextId } from "../../Shared/IdHelpers";
import * as ACTIONS from "../actions/scenarioActions";

const defaultState = {
  configuration: data.configuration,
  scenarios: {},
  viewState: {
    loading: true,
    selectedScenarioId: null,
    compare: false,
    compareSelectedScenarioIds: [],
    unsavedChange: false
  }
};

const defaultScenarios = {
  "1": {
    id: 1,
    name: "My Scenario",
    startingYear: 2019,
    years: 30,
    incomeSources: {},
    expenses: {},
    accounts: {}
  }
};

const scenarioReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ACTIONS.SCENARIOS_LOADED: {
      const scenarios = {
        ...(action.scenarios || defaultScenarios)
      };
      return Object.assign({}, state, {
        viewState: {
          ...state.viewState,
          loading: false,
          selectedScenarioId: Object.values(scenarios)[0].id
        },
        scenarios
      });
    }
    case ACTIONS.SCENARIOS_SAVED: {
      return Object.assign({}, state, {
        viewState: {
          ...state.viewState,
          unsavedChange: false
        }
      });
    }
    case ACTIONS.SCENARIO_UPDATE:
      return Object.assign({}, state, {
        scenarios: {
          ...state.scenarios,
          [action.scenario.id]: action.scenario
        },
        viewState: {
          ...state.viewState,
          unsavedChange: true
        }
      });
    case ACTIONS.SCENARIO_COPY:
      var newId = getNextId(state.scenarios);
      const sourceScenario = state.scenarios[action.scenarioId];
      const newScenario = {
        ...sourceScenario,
        name: "Copy of " + sourceScenario.name,
        id: newId
      };
      return Object.assign({}, state, {
        scenarios: {
          ...state.scenarios,
          [newScenario.id]: newScenario
        },
        viewState: {
          ...state.viewState,
          unsavedChange: true
        }
      });
    case ACTIONS.SCENARIO_DELETE:
      const newScenarios = {
        ...state.scenarios
      };

      delete newScenarios[action.scenarioId];
      return Object.assign({}, state, {
        scenarios: {
          ...newScenarios
        },
        viewState: {
          ...state.viewState,
          unsavedChange: true
        }
      });
    case ACTIONS.SCENARIO_COMPARE_ENTER:
      const compareSelectedScenarioIds = Object.values(state.scenarios).map(
        s => s.id
      );
      return Object.assign({}, state, {
        viewState: {
          ...state.viewState,
          compare: true,
          compareSelectedScenarioIds
        }
      });
    case ACTIONS.SCENARIO_COMPARE_EXIT:
      return Object.assign({}, state, {
        viewState: {
          ...state.viewState,
          compare: false
        }
      });
    case ACTIONS.SCENARIO_SELECT:
      return Object.assign({}, state, {
        viewState: {
          selectedScenarioId: action.scenarioId
        }
      });
    case ACTIONS.SCENARIO_COMPARE_SELECT:
      const newIds = [...state.viewState.compareSelectedScenarioIds];
      const index = newIds.indexOf(action.scenarioId);
      if (index !== -1) {
        newIds.splice(index, 1);
      } else {
        newIds.push(action.scenarioId);
      }
      return Object.assign({}, state, {
        viewState: {
          ...state.viewState,
          compareSelectedScenarioIds: newIds
        }
      });
    default:
      return state;
  }
};

export default scenarioReducer;
