import React, { Component } from "react";
import { extractInputData } from "../Shared/FormHelpers";
import AddCollectionItem from "./AddCollectionItem";
import ItemVariableInput, { InputSize } from "./ItemVariableInput";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getNextId } from "../Shared/IdHelpers";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
};

Modal.setAppElement("#root");

class InputCollection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: {
        isOpen: false
      },
      collection: props.collection.reduce((accum, item) => {
        accum[item.id] = {
          id: item.id,
          isExpanded: false
        };
        return accum;
      }, {})
    };
  }

  handleExpandToggle = itemId => () => {
    var originalState = this.state.collection[itemId];
    this.setState({
      collection: {
        ...this.state.collection,
        [itemId]: {
          ...originalState,
          isExpanded: !originalState.isExpanded
        }
      }
    });
  };

  handleToggleAll = isExpanded => () => {
    this.setState({
      collection: Object.values(this.state.collection).reduce((accum, item) => {
        accum[item.id] = {
          ...this.state.collection[item.id],
          isExpanded
        };

        return accum;
      }, {})
    });
  };

  onFieldChange = (item, type) => event => {
    const inputData = extractInputData(type, event);
    this.props.onEdit({
      ...item,
      [inputData.name]: inputData.value
    });
  };

  onVariableRuleChange = (item, variableRule, type) => event => {
    const inputData = extractInputData(type, event);
    this.props.onEdit({
      ...item,
      variableRules: {
        ...item.variableRules,
        [variableRule.id]: {
          ...variableRule,
          [inputData.name]: inputData.value
        }
      }
    });
  };

  // TODO: convert some of these to actions / reducers, once codebase stabilizes
  addVariableRule = item => () => {
    var nextId = getNextId(item.variableRules);
    this.props.onEdit({
      ...item,
      variableRules: {
        ...item.variableRules,
        [nextId]: { id: nextId }
      }
    });
  };

  deleteVariableRule = (item, variableRule) => () => {
    var newVariableRules = {
      ...item.variableRules
    };

    delete newVariableRules[variableRule.id];

    this.props.onEdit({
      ...item,
      variableRules: {
        ...newVariableRules
      }
    });
  };

  handleModalOpen = () => {
    this.setState({
      modal: {
        isOpen: true
      }
    });
  };

  handleModalClose = () => {
    this.setState({
      modal: {
        isOpen: false
      }
    });
  };

  handleModalSave = item => {
    this.handleModalClose();

    if (!item.id) {
      item.id = getNextId(this.state.collection);
    }

    this.setState({
      collection: {
        ...this.state.collection,
        [item.id]: {
          id: item.id,
          isExpanded: true
        }
      }
    });
    this.props.onAdd(item);
  };

  render = () => {
    const metadata = this.props.metadata;
    const collection = this.props.collection;

    return (
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th scope="col" width={80}>
                <div
                  className="button-container"
                  onClick={this.handleModalOpen}
                >
                  <FontAwesomeIcon
                    icon={"plus-circle"}
                    title={"Add " + metadata.itemDisplayName}
                  />
                </div>
              </th>
              {metadata.variables.map(variable => {
                return (
                  <th key={variable.displayName}>{variable.displayName}</th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {collection.map((item, index) => {
              const variableSource = metadata[metadata.rules.source.property];
              const rulesValue = item[metadata.rules.source.controlVariable];
              const rulesVariables = variableSource[rulesValue].variables;

              return [
                <tr key={index}>
                  <td>
                    <div className="row mx-auto">
                      <div
                        className="button-container"
                        onClick={() => this.props.onDelete(item.id)}
                      >
                        <FontAwesomeIcon
                          icon="trash-alt"
                          title={"Delete " + metadata.itemDisplayName}
                        />
                      </div>
                      {rulesVariables.length > 0 ? (
                        <div
                          className="button-container"
                          onClick={this.handleExpandToggle(item.id)}
                        >
                          <FontAwesomeIcon
                            icon={
                              this.state.collection[item.id].isExpanded
                                ? "chevron-circle-up"
                                : "chevron-circle-down"
                            }
                            title={
                              this.state.collection[item.id].isExpanded
                                ? "Collapse"
                                : "Expand"
                            }
                          />
                        </div>
                      ) : null}
                    </div>
                  </td>
                  {metadata.variables.map(variable => {
                    const variableValue = item[variable.id];

                    if (variable.id === metadata.rules.source.controlVariable) {
                      if (!variableSource[variableValue]) {
                        console.log("source not found");
                      }

                      return (
                        <td key={variable.id}>
                          {variableSource[variableValue].displayName}
                        </td>
                      );
                    }

                    return (
                      <td key={variable.id}>
                        <ItemVariableInput
                          size={InputSize.Small}
                          variable={variable}
                          metadata={this.props.metadata}
                          value={variableValue}
                          onChange={this.onFieldChange(item, variable.type)}
                        />
                      </td>
                    );
                  })}
                </tr>,
                this.state.collection[item.id].isExpanded &&
                // TODO: this should be handled better
                rulesVariables.length > 0 ? (
                  <tr
                    key={item.id + "-expanded"}
                    className="mft-variable-rules"
                  >
                    <td />
                    <td colSpan={metadata.variables.length}>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col" width={80}>
                                <div
                                  className="button-container"
                                  onClick={this.addVariableRule(item)}
                                >
                                  <FontAwesomeIcon
                                    icon={"plus-circle"}
                                    title={"Add Rule"}
                                  />
                                </div>
                              </th>
                              <th scope="col">From Year</th>
                              <th scope="col">To Year</th>
                              {rulesVariables.map(variable => {
                                return (
                                  <th key={variable.id}>
                                    {variable.displayName}
                                  </th>
                                );
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            {Object.values(item.variableRules).map(
                              (variableRule, index) => {
                                return (
                                  <tr key={index}>
                                    <td>
                                      <div className="row mx-auto">
                                        <div
                                          className="button-container"
                                          onClick={this.deleteVariableRule(
                                            item,
                                            variableRule
                                          )}
                                        >
                                          <FontAwesomeIcon icon="trash-alt" />
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        className="form-control-sm"
                                        id="fromYear"
                                        name="fromYear"
                                        value={variableRule.fromYear || ""}
                                        onChange={this.onVariableRuleChange(
                                          item,
                                          variableRule,
                                          "number"
                                        )}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        className="form-control-sm"
                                        id="toYear"
                                        name="toYear"
                                        value={variableRule.toYear || ""}
                                        onChange={this.onVariableRuleChange(
                                          item,
                                          variableRule,
                                          "number"
                                        )}
                                      />
                                    </td>
                                    {rulesVariables.map(variable => {
                                      return (
                                        <td key={variable.id}>
                                          <ItemVariableInput
                                            size={InputSize.Small}
                                            variable={variable}
                                            metadata={this.props.metadata}
                                            value={variableRule[variable.id]}
                                            onChange={this.onVariableRuleChange(
                                              item,
                                              variableRule,
                                              variable.type
                                            )}
                                          />
                                        </td>
                                      );
                                    })}
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                ) : null
              ];
            })}
          </tbody>
        </table>
        <Modal
          isOpen={this.state.modal.isOpen}
          onRequestClose={this.handleModalClose}
          style={customStyles}
          contentLabel={"Edit " + metadata.itemDisplayName}
        >
          <AddCollectionItem
            metadata={metadata}
            startingYear={this.props.startingYear}
            onSave={this.handleModalSave}
            onCancel={this.handleModalClose}
          />
        </Modal>
      </div>
    );
  };
}

export default InputCollection;
