import React from "react";
import NumberFormat from "react-number-format";

export const InputSize = {
  Default: "default",
  Small: "small"
};

const sizeClass = {
  default: "form-control",
  small: "form-control-sm"
};

const ItemVariableInput = props => {
  const inputClass = sizeClass[props.size] || sizeClass[InputSize.Default];

  // TODO: handle assumptions here better with select list
  switch (props.variable.type) {
    case "select":
      return (
        <select
          className={inputClass}
          id={props.variable.id}
          name={props.variable.id}
          value={props.value}
          onChange={props.onChange}
        >
          <option>Select.. </option>
          {Object.values(
            props.metadata[props.metadata.rules.source.property]
          ).map(type => (
            <option key={type.id} value={type.id}>
              {type.displayName}
            </option>
          ))}
        </select>
      );
    case "money":
      return (
        <NumberFormat
          getInputRef={el => el && el.classList.add(inputClass)}
          value={props.value}
          decimalScale={0}
          thousandSeparator={true}
          onValueChange={values => {
            props.onChange({
              target: {
                name: props.variable.id,
                value: values.floatValue
              }
            });
          }}
        />
      );
    default:
      return (
        <input
          type={props.variable.type}
          className={inputClass}
          id={props.variable.id}
          name={props.variable.id}
          value={props.value}
          onChange={props.onChange}
          checked={props.variable.type === "checkbox" && props.value}
        />
      );
  }
};

export default ItemVariableInput;
