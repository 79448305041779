import React from "react";
import { extractInputData } from "../Shared/FormHelpers";
import NumberFormat from "react-number-format";

const ScenarioForm = props => {
  const onFieldChange = type => event => {
    const inputData = extractInputData(type, event);

    props.onEdit({
      ...props.scenario,
      [inputData.name]: inputData.value
    });
  };

  return (
    <form className="mft-scenario-form">
      <div className="form-group row">
        <label className="col-sm-2" htmlFor="name">
          Scenario Name
        </label>
        <div className="col-sm-10">
          <input
            className="form-control"
            type="text"
            name="name"
            value={props.scenario.name}
            onChange={onFieldChange("text")}
          />
        </div>
      </div>
      <div className="form-group row">
        <label className="col-sm-2" htmlFor="years">
          Years
        </label>
        <div className="col-sm-10">
          <input
            className="form-control"
            type="text"
            name="years"
            value={props.scenario.years}
            onChange={onFieldChange("number")}
          />
        </div>
      </div>
      <div className="form-group row">
        <label className="col-sm-2" htmlFor="targetWithdrawalRate">
          Target Withdrawal Rate
        </label>
        <div className="col-sm-10">
          <input
            className="form-control"
            type="text"
            name="targetWithdrawalRate"
            value={props.scenario.targetWithdrawalRate}
            onChange={onFieldChange("number")}
          />
        </div>
      </div>
      <div className="form-group row">
        <label className="col-sm-2" htmlFor="targetYearlyIncome">
          Target Yearly Income
        </label>
        <div className="col-sm-10">
          <NumberFormat
            getInputRef={el => el && el.classList.add("form-control")}
            value={props.scenario.targetYearlyIncome}
            decimalScale={0}
            thousandSeparator={true}
            onValueChange={values => {
              props.onEdit({
                ...props.scenario,
                targetYearlyIncome: values.floatValue
              });
            }}
          />
        </div>
      </div>
      {/* TODO: Should this be here?  Could move to header over forecast */}
      <div className="form-group row">
        <label className="col-sm-2" htmlFor="targetYearlyIncome">
          Target Net Worth
        </label>
        <div className="col-sm-10">
          <NumberFormat
            value={props.forecast.targetNetWorth}
            displayType={"text"}
            decimalScale={0}
            thousandSeparator={true}
          />
        </div>
      </div>
      {/* <div className="form-group row">
        <label className="col-sm-2" htmlFor="growthRate">
          Growth Rate
        </label>
        <div className="col-sm-10">
          <input
            className="form-control"
            type="text"
            name="growthRate"
            value={scenario.growthRate}
            onChange={this.onFieldChange("number")}
          />
        </div>
      </div> */}
    </form>
  );
};

export default ScenarioForm;
