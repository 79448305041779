import React from "react";
import { withRouter } from "react-router-dom";
import { withFirebase } from "./components/firebase";
import { Nav, Navbar } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import * as ROUTES from "./constants/routes";
import { compose } from "redux";

const MyNavbar = props => {
  const signOut = () => {
    props.firebase.signOut();
    props.history.push(ROUTES.HOME);
  };

  const signIn = () => {
    props.history.push(ROUTES.SIGNIN);
  };

  return (
    <Navbar bg="light" expand="lg">
      <LinkContainer to={ROUTES.HOME}>
        <Navbar.Brand>My Financial Tool</Navbar.Brand>
      </LinkContainer>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mft-nav-main mr-auto">
          <Nav.Item>
            <LinkContainer to={ROUTES.DEMO}>
              <Nav.Link eventKey={1}>Demo</Nav.Link>
            </LinkContainer>
          </Nav.Item>
          <Nav.Item>
            <LinkContainer to={ROUTES.PLAN}>
              <Nav.Link eventKey={2}>My Plan</Nav.Link>
            </LinkContainer>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href="https://www.surveymonkey.com/r/V8KZYW2">
              Survey
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Nav className="mft-nav-user ml-auto">
          {props.user ? (
            [
              <Nav.Item key="user-email">
                <span className="align-middle">{props.user.email}</span>
              </Nav.Item>,
              <Nav.Item key="sign-out">
                <button className="btn btn-sm btn-dark" onClick={signOut}>
                  Sign Out
                </button>
              </Nav.Item>
            ]
          ) : (
            <button className="btn btn-sm btn-dark" onClick={signIn}>
              Sign In
            </button>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default compose(
  withRouter,
  withFirebase
)(MyNavbar);
