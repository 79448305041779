import React, { Component } from "react";
import { withAuthorization } from "../components/session";
import { connect } from "react-redux";
import { compose } from "recompose";
import * as ACTIONS from "../state/actions";
import { getUser } from "../state/selectors";
import ScenarioContainer from "./ScenarioContainer";

class UserScenarios extends Component {
  componentDidMount = () => {
    this.listener = this.props.firebase.db
      .collection("users")
      .doc(this.props.user.uid)
      .onSnapshot(userDoc => {
        const data = userDoc.data();
        this.props.onScenariosLoaded(data ? data.scenarios : undefined);
      });
  };

  componentWillUnmount = () => {
    this.listener();
  };

  saveScenarios = scenarios => {
    // TODO: handle async success/error
    this.props.firebase.db
      .collection("users")
      .doc(this.props.user.uid)
      .set({
        scenarios
      });
    this.props.onScenariosSaved();
  };

  render = () => {
    return <ScenarioContainer onSaveScenarios={this.saveScenarios} />;
  };
}

function mapStateToProps(state) {
  return {
    user: getUser(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onScenariosLoaded: scenarios => {
      dispatch({ type: ACTIONS.SCENARIOS_LOADED, scenarios });
    },
    onScenariosSaved: () => {
      dispatch({ type: ACTIONS.SCENARIOS_SAVED });
    }
  };
}

const condition = user => !!user;

export default compose(
  withAuthorization(condition),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(UserScenarios);
