import React, { Component } from "react";
import {
  FlexibleXYPlot,
  XAxis,
  YAxis,
  Crosshair,
  AreaSeries,
  HorizontalGridLines,
  DiscreteColorLegend,
  LineSeries
} from "react-vis";
import NumberFormat from "react-number-format";

class ForecastGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      crosshairValues: [],
      highlighted: false
    };
  }

  handleNearestX = event => {
    var forecast = this.props.forecast;
    var crosshairValues = forecast.accounts
      .map(account => {
        const accountBalance = account.years[event.x].endingBalance;
        return {
          x: event.x,
          title: account.name,
          accountBalance: accountBalance,
          value: (
            <NumberFormat
              value={accountBalance}
              displayType={"text"}
              decimalScale={0}
              thousandSeparator={true}
            />
          )
        };
      })
      .filter(series => series.accountBalance !== 0);

    crosshairValues.push({
      x: event.x,
      title: "Net Worth",
      value: (
        <NumberFormat
          value={forecast.years[event.x].endingValue}
          displayType={"text"}
          decimalScale={0}
          thousandSeparator={true}
        />
      )
    });

    crosshairValues = crosshairValues.map(series => {
      if (this.state.highlighted === series.title) {
        return {
          x: event.x,
          title: <strong>{series.title}</strong>,
          value: <strong>{series.value}</strong>
        };
      }

      return series;
    });

    this.setState({
      crosshairValues
    });
  };

  handleMouseLeave = () => {
    this.setState({ crosshairValues: [] });
  };

  render = () => {
    return this.props.forecast.accountSeries.length > 0 ? (
      <div className="mft-forecast-graph">
        <div className="mft-forecast-graph-legend">
          <DiscreteColorLegend
            height={40}
            orientation="horizontal"
            onItemMouseEnter={seriesName =>
              this.setState({ highlighted: seriesName })
            }
            onItemMouseLeave={() => this.setState({ highlighted: false })}
            items={this.props.forecast.accountSeries.map(series => series.name)}
          />
        </div>
        <div key={"forcast-chart"} className="mft-forecast-graph-chart">
          <FlexibleXYPlot onMouseLeave={this.handleMouseLeave}>
            <HorizontalGridLines />
            {this.props.forecast.accountSeries.map((series, index) => {
              return (
                <AreaSeries
                  data={series.data}
                  key={series.name}
                  opacity={
                    this.state.highlighted
                      ? this.state.highlighted === series.name
                        ? 0.8
                        : 1
                      : 1
                  }
                  style={
                    this.state.highlighted === series.name
                      ? { stroke: "black", strokeWidth: "5px" }
                      : null
                  }
                  onNearestX={index === 0 ? this.handleNearestX : null}
                  onSeriesMouseOver={() =>
                    this.setState({ highlighted: series.name })
                  }
                  onSeriesMouseOut={() => this.setState({ highlighted: false })}
                />
              );
            })}
            {this.props.forecast.totalSeries.map((series, index) => {
              return (
                <LineSeries
                  data={series.data}
                  key={series.name}
                  style={
                    this.state.highlighted === series.name
                      ? { stroke: "black", strokeWidth: "5px" }
                      : null
                  }
                  onSeriesMouseOver={() =>
                    this.setState({ highlighted: series.name })
                  }
                  onSeriesMouseOut={() => this.setState({ highlighted: false })}
                />
              );
            })}

            <Crosshair
              values={this.state.crosshairValues}
              titleFormat={d => ({ title: "Year", value: d[0].x })}
              itemsFormat={d => d}
            />
            <XAxis
              title={"Year"}
              tickFormat={x => x.toString()}
              tickTotal={this.props.forecast.variables.years}
            />
            <YAxis title={"Value (1000s)"} tickFormat={y => y / 1000} />
          </FlexibleXYPlot>
        </div>
      </div>
    ) : null;
  };
}

export default ForecastGraph;
