import React from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { withFirebase } from "./firebase";

const Login = props => {
  return (
    <div className="mft-login">
      <StyledFirebaseAuth
        uiConfig={props.firebase.authUiConfig}
        firebaseAuth={props.firebase.auth}
      />
    </div>
  );
};

export default withFirebase(Login);
