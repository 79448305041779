export const extractInputData = (type, event) => {
  let value = "";

  switch (type) {
    case "number":
    case "money":
      if (event.target.value !== "") {
        value = +event.target.value;
      }
      break;
    case "checkbox":
      value = event.target.checked;
      break;
    default:
      value = event.target.value;
  }

  return {
    name: event.target.name,
    value
  };
};

export const defaultValue = type => {
  switch (type) {
    case "number":
      return 0;
    case "checkbox":
      return false;
    default:
      return "";
  }
};
