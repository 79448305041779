import React, { Component } from "react";
import ForecastCashFlow from "./ForecastCashFlow";
import { Tabs, Tab } from "react-bootstrap";
import ForecastGraph from "./ForecastGraph";
import ForecastAccounts from "./ForecastAccounts";

class Forecast extends Component {
  render = () => {
    return (
      <div className="mft-forecast">
        <Tabs defaultActiveKey="graph">
          <Tab eventKey="graph" title="Forecast Graph" unmountOnExit={true}>
            <ForecastGraph forecast={this.props.forecast} />
          </Tab>
          <Tab eventKey="accounts" title="Forecast Accounts">
            <ForecastAccounts forecast={this.props.forecast} />
          </Tab>
          <Tab eventKey="cashFlow" title="Forecast Cash Flow">
            <ForecastCashFlow data={this.props.forecast.years} />
          </Tab>
        </Tabs>
      </div>
    );
  };
}

export default Forecast;
